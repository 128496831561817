.ImageGrid-container {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  align-content: center;
}

.ImageGrid-image-sub-container {
  margin: 0 auto;
}

.ImageGrid-image-title-container {
  padding-top: 6px;
  width: 100%;
  text-align: center;
}

.ImageGrid-image-title {
  padding-top: 6px;
}

.ImageGrid-no-image {
  padding: 40px 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
