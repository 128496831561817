.Simplemark h1,
.Simplemark h2,
.Simplemark h3,
.Simplemark h4,
.Simplemark h5,
.Simplemark h6 {
  font-weight: 500;
  margin: 24px 0 16px 0;
  line-height: 1.25;
}
.Simplemark h1 {
  font-size: 1.7rem;
}
.Simplemark h2 {
  font-size: 1.5rem;
}
.Simplemark h3 {
  font-size: 1.3rem;
}
.Simplemark h4 {
  font-size: 1.2rem;
}
.Simplemark h5,
.Simplemark h6 {
  font-size: 1.1rem;
}

.Simplemark p {
  line-height: 1.5;
  margin: 1px 0 7px 0;
}

.Simplemark a {
  text-decoration: underline;
}

.Simplemark em {
  font-style: italic;
}

.Simplemark strong {
  font-weight: 600;
}
