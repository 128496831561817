.Overlay-background {
  z-index: 100;
  position: fixed;
  top: 0px;
  /*Have to set height and width (not use t,b,l,r = 0) in order to use top: 30% in content*/
  height: 100%;
  width: 100vw;
}

.Overlay-content-text,
.Overlay-content-request-fullscreen {
  margin: 0 auto;
  position: relative;
  top: 30%;
  transform: translate(0%, -30%);
  max-height: 100%; /*use background padding for top and bottom space*/
  border: 1px solid;
  border-radius: 8px;
  -webkit-overflow-scrolling: touch;
  outline: none;
}
.Overlay-request-fullscreen-button-container {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Overlay-content-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Overlay-body-text {
  overflow-y: auto;
  outline: none;
  padding: 2px 36px 8px;
  text-indent: 40px;
}

.Overlay-body-text > p:last-child {
  margin-bottom: 3px;
}

.Overlay-short-text p {
  text-align: center;
  text-indent: 0px;
}

.Overlay-header {
  padding: 15px 0 12px;
  text-align: center;
}
.Overlay-footer {
  padding: 6px 0 12px;
  text-align: center;
}
