.InfoMenu-container {
  position: relative;
  flex-shrink: 0;
  padding: 0 8px;
  margin: 0 -8px 0 0;
}

/*
required because of a bug in firefox v88 that will split "Additional Information"
into two lines if display: inline (which is the default for <a>)
*/
.InfoMenu-menu-link {
  display: inline-block;
}

.InfoMenu-items-container {
  /* reset font-size so it's not inherited from header */
  font-size: 1rem;
  width: 100%;
  position: absolute;
  margin: 0 0 0 -8px;
  border-radius: 0 0 8px 8px;
}

.InfoMenu-items-ul {
  padding: 0 8px 5px;
  border-radius: inherit;
}

.InfoMenu-item {
  margin: 5px 0 0;
}
