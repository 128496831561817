.RequestFullscreenButton-container {
  padding: 0 30px;
}

.RequestFullscreenButton-button {
  width: 100%;
  text-align: center;
  padding: 6px 7px 4px;
  border: 2px solid;
}

.RequestFullscreenButton-description {
  margin: 20px auto 0;
}
