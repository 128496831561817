/* padding box hack used to maintain aspect ratio of image while being responsive
see: https://matthiasott.com/notes/aspect-ratio-in-css */
.ImageFitWidth-box-of-padding {
  margin: 0 auto; /* to center box of padding horizontally inside it's container */
  position: relative; /* for stuff inside of it */
  width: 0px;
  height: 0px;
}

/*so the child of the box of padding is the same size of the box of padding*/
.ImageFitWidth-box-of-padding-child {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ImageFitWidth-link,
.ImageFitWidth-image {
  display: block;
  height: 100%;
  width: 100%;
}
