/* prettier-ignore */
button, a {
  all: unset;
}

/* prettier-ignore */
*, *::before, *::after, button, a {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: inherit;
  font: inherit;
  font-weight: inherit;
  text-align: inherit;
  line-height: inherit;
  word-break: inherit;
  color: inherit;
  fill: inherit;
  background: transparent;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
ul {
  list-style: none;
}

/*pass window height (w/o scroll bars) down to react app, note can't use vh because that
includes scroll bars and mobile browser footer, etc so doesn't give viewable area*/
html,
body,
#root {
  height: 100%;
}

body {
  font-family: system-ui, -apple-system, Helvetica, Verdana, sans-serif;
  font-weight: normal;
  /* use word-break instead of "overflow-wrap: anywhere" because of Safari support */
  word-break: break-word;
  line-height: 1.2;
  letter-spacing: 0.2px;
  box-sizing: border-box;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*required for IE/Edge to be compatible with left:50vw & transform:translate(-50%, 0),
  otherwise there is a phantom div on the right side of the screen (from the left:50vw),
  that the window will scroll to. Note the left:50vw and transform is used to center content
  horizontally based on vw to prevent content jump when vertical scroll bar show/hide.*/
  /*Or use flexbox instead (w/ 100vw container), but still need overflow-x: hidden
  b/c create horizontal scroll bar to show 100vw width of flex container*/
  /*Or use margin: auto inside of a 100vw container, but still need overflow-x: hidden
  b/c create horizontal scroll bar to show 100vw width of container*/
  overflow-x: hidden;
}

/* by default inline-block/svgs are vertical-align: baseline,
but this makes the browser add space below for descenders (j,g,y,etc)
which causes problems when the svg is a child of a button
see: https://stackoverflow.com/questions/51215758/button-containing-svg-is-of-unexpected-height
see: https://stackoverflow.com/questions/45423874/button-height-is-greater-than-the-nested-contents-height
*/
svg {
  vertical-align: middle;
}

img {
  /*so users can't highlight/select images with mouse, i.e. won't receive ::selection styles,
  note that this does NOT prevent images from being dragged and dropped
  and saved on long press/right click context menu*/
  user-select: none;

  /*so images don't receive mouse and touch events (they pass through like img doesn't exist),
  prevents context menu on long press in Chrome and Firefox on Android to save/open image,
  prevents images from being dragged and dropped with mouse and right click context menu,
  prevents long press to float and grab image on iPad Safari iOS 11*/
  pointer-events: none;

  /*note that if the img is wrapped in a link (a tag),
  then the link can still be selected and
  show context menu (but for link only, so no save image, just open/copy link etc)*/
}
