.HomePageNarrow-container,
.HomePageWide-container {
  width: 100vw;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.HomePageNarrow-container > .ImageGrid-container,
.HomePageWide-container > .ImageGrid-container {
  width: 100%;
  flex-grow: 1;
}
