.NarrowHeader {
  padding: 16px 20px 20px;
  text-align: center;
}

.WideHeader-container {
  width: 100vw;
}

.WideHeader-container {
  position: sticky;
  /* required to be 0px, not 0, the prevent bug on touch devices
  where sometimes a small gap is shown about the header when interacting with the page */
  top: 0px;
  z-index: 10;
}

.WideHeader-sub-container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.WideHeader-breadcrumbs {
  margin-right: 20px;
}

.WideHeader-breadcrumbs-separator {
  margin: 0 7px;
}
