.ImagePageOverlay {
  user-select: none;
  text-align: center;
  position: absolute;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
}

.ImagePageOverlay-content {
  margin-top: 10px;
}
