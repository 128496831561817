.UserLoading-container {
  font-size: 1.5rem;
  height: 100%;
  min-height: 100px;
  width: 100%;
  padding: 0 10vw 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UserLoading-text {
  width: 100%;
  text-align: center;
}
