.MenuPage-container {
  width: 100vw;
}

.MenuPage-request-fullscreen-container {
  width: 100vw;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 60px;
}

.MenuPage-items-container {
  padding: 42px 5vw 70px;
}

.MenuPage-item {
  margin: 28px 0;
}

.MenuPage-item-title {
  display: block;
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.MenuPage-item-title-shown {
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-decoration-thickness: from-font;
}

.MenuPage-item-content p {
  margin: 0 0 14px 0;
}

.MenuPage-item-content > p:last-child {
  margin-bottom: 36px;
}
