.Loading-container {
  height: 100%;
  width: 100%;
  padding: 0 10vw 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Loading-text {
  width: 100%;
  text-align: center;
}
