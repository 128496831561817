.FooterSticky-container {
  width: 100vw;
  position: sticky;
  /*bottom needs to be 0px not 0 to avoid bug on Android Chrome where footer
  moves up and down slightly on scroll */
  bottom: 0px;
  z-index: 10;
}

.FooterSticky-sub-container {
  width: 100%;
}

.FooterSticky-sub-container > * {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.FooterNarrowSingleButton-container {
  display: flex;
  justify-content: center;
}

.FooterNarrowMultiButton-container {
  width: 100vw;
  position: relative;
}

.FooterNarrowMultiButton-sub-container {
  padding: 10px 0;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  justify-content: center;
}
